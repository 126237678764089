import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import { useState } from 'react';

import { cn } from '@/lib/utils';
import { getEnergyColor } from '@/utils/helpers';

const tileVariants = cva('group focus:outline-none relative', {
  variants: {
    size: {
      default: 'w-auto',
      fixed: 'w-48',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export interface TileProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    VariantProps<typeof tileVariants> {
  asChild?: boolean;
  href?: string;
}

const Tile = React.forwardRef<HTMLAnchorElement, TileProps>(
  ({ className, size, asChild = false, href, ...props }, ref) => {
    const tileClass = cn(tileVariants({ size, className }), 'flex flex-col');

    if (asChild) {
      return <Slot className={tileClass} ref={ref} {...props} />;
    }

    if (!href) {
      throw new Error(
        'Tile component requires an href prop when not using asChild',
      );
    }

    return <Link className={tileClass} ref={ref} href={href} {...props} />;
  },
);

Tile.displayName = 'Tile';

const TileImage = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Image>
>(({ className, fill = true, ...props }, ref) => (
  <div
    className="relative aspect-square w-full overflow-hidden rounded-[10%] group-focus-visible:ring-2 group-focus-visible:ring-uppbeat group-focus-visible:ring-offset-2 group-focus-visible:ring-offset-white dark:group-focus-visible:ring-offset-gray-dark-400 transition"
    ref={ref}
  >
    <Image
      className={cn('object-cover', className)}
      fill={fill}
      loading="lazy"
      sizes={[
        '(max-width: 767px) 128px',
        '(max-width: 991px) 248px',
        '(max-width: 1100px) 206px',
        '(max-width: 1600px) 180px',
        '200px',
      ].join(', ')}
      {...props}
    />
  </div>
));

TileImage.displayName = 'TileImage';

const TileContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('py-2 px-0 pr-2 overflow-hidden flex-grow', className)}
    {...props}
  />
));

TileContent.displayName = 'TileContent';

interface TileTextProps extends React.HTMLAttributes<HTMLElement> {
  asChild?: boolean;
}

const TileTitle = React.forwardRef<HTMLHeadingElement, TileTextProps>(
  ({ className, asChild = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'h3';

    return (
      <Comp
        ref={ref}
        className={cn(
          '~text-sm/base font-medium mt-2 mb-1 truncate group-focus-visible:underline group-focus-visible:underline-offset-1',
          className,
        )}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);

TileTitle.displayName = 'TileTitle';

const TileDescription = React.forwardRef<HTMLParagraphElement, TileTextProps>(
  ({ className, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p';

    return (
      <Comp
        ref={ref}
        className={cn(
          '~text-xs/sm whitespace-normal line-clamp-2 text-gray-medium-300 dark:text-gray-medium-200',
          className,
        )}
        {...props}
      />
    );
  },
);

TileDescription.displayName = 'TileDescription';

interface TileSimilarProps
  extends Omit<React.ComponentProps<typeof Image>, 'src'> {
  mainImage: string;
  leftImage?: string;
  rightImage?: string;
  trackName: string;
  artistName: string;
  energy: number;
  trackId: number;
}

const TileSimilar = React.forwardRef<HTMLDivElement, TileSimilarProps>(
  (
    {
      className,
      mainImage,
      leftImage,
      rightImage,
      trackName,
      artistName,
      energy,
      trackId,
      ...props
    },
    ref,
  ) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const backgroundColor = getEnergyColor(energy, trackId);

    // Use main image as fallback for missing side images
    const finalLeftImage = leftImage ?? mainImage;
    const finalRightImage = rightImage ?? finalLeftImage;

    return (
      <div
        className="relative aspect-square w-full overflow-hidden rounded-[10%] group-focus-visible:ring-2 group-focus-visible:ring-uppbeat group-focus-visible:ring-offset-2 group-focus-visible:ring-offset-white dark:group-focus-visible:ring-offset-gray-dark-400 transition bg-[var(--background-color)]"
        ref={ref}
        style={{ '--background-color': backgroundColor } as React.CSSProperties}
      >
        {/* Main Avatar */}
        <div className="absolute top-[33%] left-1/2 h-[46%] w-[46%] -translate-x-1/2 -translate-y-1/2 rounded-full bg-white shadow-[0_0_0_2px_rgba(255,255,255,1)] sm:shadow-[0_0_0_3px_rgba(255,255,255,1)] overflow-hidden z-10">
          <Image
            src={mainImage}
            className={cn(
              'object-cover transition-opacity',
              hasLoaded ? 'opacity-100' : 'opacity-0',
            )}
            fill
            sizes="(max-width: 767px) 60px, (max-width: 991px) 115px, (max-width: 1100px) 95px, (max-width: 1600px) 85px, 120px"
            onLoadingComplete={() => setHasLoaded(true)}
            {...props}
          />
        </div>

        {/* Left Avatar */}
        <div className="absolute top-[48%] left-[26%] h-[30%] w-[30%] sm:h-[35%] sm:w-[35%] -translate-x-1/2 -translate-y-1/2 rounded-full bg-white shadow-[0_0_0_2px_rgba(255,255,255,1)] sm:shadow-[0_0_0_3px_rgba(255,255,255,1)] overflow-hidden z-5">
          <Image
            src={finalLeftImage}
            className="object-cover"
            fill
            sizes="(max-width: 767px) 45px, (max-width: 991px) 86px, (max-width: 1100px) 71px, (max-width: 1600px) 64px, 90px"
            {...props}
          />
        </div>

        {/* Right Avatar */}
        <div className="absolute top-[48%] right-[26%] h-[30%] w-[30%] sm:h-[35%] sm:w-[35%] translate-x-1/2 -translate-y-1/2 rounded-full bg-white shadow-[0_0_0_2px_rgba(255,255,255,1)] sm:shadow-[0_0_0_3px_rgba(255,255,255,1)] overflow-hidden z-5">
          <Image
            src={finalRightImage}
            className="object-cover"
            fill
            sizes="(max-width: 767px) 45px, (max-width: 991px) 86px, (max-width: 1100px) 71px, (max-width: 1600px) 64px, 90px"
            {...props}
          />
        </div>

        {/* Track Info */}
        <div className="absolute bottom-[7.5%] sm:bottom-[6%] left-0 w-full px-2.5 text-center text-white">
          <strong className="block truncate ~text-xs/sm leading-none -mb-0.5 sm:mb-0">
            {trackName}
          </strong>
          <span className="block truncate text-xs leading-none">
            {artistName}
          </span>
        </div>
      </div>
    );
  },
);

TileSimilar.displayName = 'TileSimilar';

export {
  Tile,
  TileImage,
  TileSimilar,
  TileContent,
  TileTitle,
  TileDescription,
};
