import Image from 'next/image';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import { cn } from '@/lib/utils';

import { Tile, TileImage, TileSimilar } from './ui/tile';

import type { CSSProperties } from 'react';

interface TileCarouselContextType {
  tileRef: React.RefObject<HTMLAnchorElement>;
  tileImageRef: React.RefObject<HTMLDivElement>;
}

const TileCarouselContext = createContext<TileCarouselContextType | null>(null);

const useTileCarouselContext = () => {
  const context = useContext(TileCarouselContext);

  if (!context) {
    throw new Error(
      'TileCarousel components must be used within a TileCarouselProvider',
    );
  }

  return context;
};

interface TileCarouselProps {
  children: React.ReactNode;
  className?: string;
}

export const TileCarousel: React.FC<TileCarouselProps> = ({
  children,
  className,
}) => {
  const [api, setApi] = useState<CarouselApi>();
  const tileRef = useRef<HTMLAnchorElement>(null);
  const tileImageRef = useRef<HTMLDivElement>(null);
  const [contentOffset, setContentOffset] = useState(0);

  useEffect(() => {
    if (tileRef.current && tileImageRef.current) {
      const totalHeight = tileRef.current.clientHeight;
      const imageHeight = tileImageRef.current.clientHeight;
      const offset = totalHeight - imageHeight;

      // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
      setContentOffset(offset);
    }
  }, []);

  useEffect(() => {
    if (!api) {
      return () => {};
    }

    return () => {
      api.destroy();
    };
  }, [api]);
  const contextValue = useMemo(() => ({ tileRef, tileImageRef }), []);

  return (
    <TileCarouselContext.Provider value={contextValue}>
      <div className={cn('relative group @container', className)}>
        <Carousel
          setApi={setApi}
          opts={{
            slidesToScroll: 'auto',
          }}
        >
          <CarouselContent className="mx-container ml-container">
            {children}
          </CarouselContent>
          <CarouselPrevious
            className="w-container left-0 rounded-none z-[1] flex h-[calc(100%+2px)] bg-white/70 dark:bg-gray-dark-400/70 enabled:hover:bg-white enabled:dark:hover:bg-gray-dark-400 opacity-0 enabled:group-hover:opacity-100 disabled:opacity-0 transition-all duration-300 pb-[var(--content-offset)]"
            variant="secondary"
            tabIndex={-1}
            style={
              {
                '--content-offset': `${contentOffset}px`,
              } as CSSProperties
            }
          />
          <CarouselNext
            className="w-container right-0 rounded-none z-[1] flex h-[calc(100%+1px)] bg-white/70 dark:bg-gray-dark-400/70 enabled:hover:bg-white enabled:dark:hover:bg-gray-dark-400 opacity-0 enabled:group-hover:opacity-100 disabled:opacity-0 transition-all duration-300 pb-[var(--content-offset)]"
            variant="secondary"
            tabIndex={-1}
            style={
              {
                '--content-offset': `${contentOffset}px`,
              } as CSSProperties
            }
          />
        </Carousel>
      </div>
    </TileCarouselContext.Provider>
  );
};

interface BaseTileCarouselItemProps {
  className?: string;
  children: React.ReactNode;
  index: number;
  href?: string;
  alt?: string;
  onClick?: () => void;
}

interface DefaultTileCarouselItemProps extends BaseTileCarouselItemProps {
  variant?: 'default';
  src: string;
}

interface SimilarTileCarouselItemProps extends BaseTileCarouselItemProps {
  variant: 'similar';
  mainImage: string;
  leftImage?: string;
  rightImage?: string;
  trackName: string;
  artistName: string;
  energy: number;
  trackId: number;
}

interface CreatorPicksTileCarouselItemProps extends BaseTileCarouselItemProps {
  variant: 'creator-picks';
  src: string;
  creatorImage?: string;
  creatorName?: string;
}

type TileCarouselItemProps =
  | DefaultTileCarouselItemProps
  | SimilarTileCarouselItemProps
  | CreatorPicksTileCarouselItemProps;

export const TileCarouselItem: React.FC<TileCarouselItemProps> = ({
  className,
  children,
  index,
  href,
  alt = '',
  variant = 'default',
  onClick,
  ...props
}) => {
  const { tileRef, tileImageRef } = useTileCarouselContext();

  const defaultProps = props as DefaultTileCarouselItemProps;
  const similarProps = props as SimilarTileCarouselItemProps;
  const creatorPicksProps = props as CreatorPicksTileCarouselItemProps;

  return (
    <CarouselItem
      key={`tile-${index}`}
      className={cn(
        'basis-1/3 @[540px]:basis-1/4 @[900px]:basis-1/5 @[1150px]:basis-1/6 @[1400px]:basis-[14.28%] @[1580px]:basis-[12.5%] px-0 pr-2',
        className,
      )}
    >
      <Tile
        href={href}
        className="flex-shrink-0"
        ref={index === 0 ? tileRef : undefined}
        onClick={onClick}
      >
        <>
          {variant === 'default' && (
            <TileImage
              alt={alt}
              src={defaultProps.src}
              unoptimized
              ref={index === 0 ? tileImageRef : undefined}
            />
          )}
          {variant === 'similar' && (
            <TileSimilar
              ref={index === 0 ? tileImageRef : undefined}
              mainImage={similarProps.mainImage}
              leftImage={similarProps.leftImage}
              rightImage={similarProps.rightImage}
              trackName={similarProps.trackName}
              artistName={similarProps.artistName}
              energy={similarProps.energy}
              trackId={similarProps.trackId}
              alt={alt}
            />
          )}
          {variant === 'creator-picks' && (
            <div
              className="relative overflow-hidden"
              ref={index === 0 ? tileImageRef : undefined}
            >
              <TileImage alt={alt} src={creatorPicksProps.src} unoptimized />
              {creatorPicksProps.creatorName ? (
                <div
                  className="absolute bottom-0 w-full h-full flex gap-2 z-30 px-[10px] py-[9px] overflow-hidden text-ellipsis rounded-[10%] text-white text-[11px] font-medium font-primary items-end"
                  style={{
                    background:
                      'linear-gradient(180deg, transparent 0%, transparent calc(100% - 40px), rgba(0,0,0,0.85) 100%)',
                  }}
                >
                  {creatorPicksProps.creatorImage ? (
                    <Image
                      src={creatorPicksProps.creatorImage}
                      alt={creatorPicksProps.creatorName}
                      width={20}
                      height={20}
                      className="rounded-full object-cover"
                      unoptimized={creatorPicksProps.creatorImage.startsWith(
                        'data:',
                      )}
                      sizes="(max-width: 767px) 20px, (max-width: 991px) 20px, (max-width: 1100px) 20px, (max-width: 1600px) 20px, 20px"
                    />
                  ) : null}
                  <span className="truncate">
                    {creatorPicksProps.creatorName}
                  </span>
                </div>
              ) : null}
            </div>
          )}
          {children}
        </>
      </Tile>
    </CarouselItem>
  );
};
